import React from 'react'
import { Helmet } from 'react-helmet';
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";

export default function Blog() {
    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta name="description" content="This is a description of your page." />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>
            <Header />
            {/* <section className='breadcum-bg'></section> */}
            <div className="carousel slide" data-bs-ride="carousel">

                <picture>
                    <source media="(min-width:768px)" srcset={require("../assets/images/banner.jpg")} />
                    <source media="(max-width:768px)" srcset={require("../assets/images/mbanner.jpg")} />
                    <img src={require("../assets/images/banner.jpg")} alt="Flowers" style={{ width: '100%' }} />
                </picture>

                <div className="carousel-caption carousel-content ">
                    <h3>
                        Unbox Mysuru Biryani
                    </h3>

                </div>
            </div>
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-4">
                            <h2 className='theme-color'>Blogs</h2>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                            <img src={require("../assets/images/blog1.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>The Art of Pairing Mysore Biryani with the Perfect Accompaniments</h3>
                                <p className="pb-3 comn-fnt px-2">Mysore Biryani, with its rich flavors and aromatic spices, is a culinary delight that stands tall among India's diverse biryani offerings. While this iconic dish shines on its own... </p>
                            </div>
                            <Link to={`/blog/blogs`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                            <img src={require("../assets/images/blog2.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'> From Street Food to Fine Dining: Biryani's Journey Through Culinary History</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, with its aromatic blend of spices and succulent meat or vegetables layered with fragrant rice, holds a special place in the hearts of food enthusiasts worldwide... </p>
                            </div>
                            <Link to={`./blog2`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                            <img src={require("../assets/images/bolg3.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>Exploring Regional Variations: A Tour of India's Biryani Hotspots</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, the crown jewel of Indian cuisine, boasts a rich tapestry of regional variations, each offering a unique blend of flavors, spices, and cooking techniques...</p>
                            </div>
                            <Link to={`./blog3`} className="readmore-button" > Read more </Link>
                        </div>


                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
