import React from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />
      {/* <section className="breadcum-bg "></section> */}
      <div className="carousel slide" data-bs-ride="carousel">

        <picture>
          <source media="(min-width:768px)" srcset={require("../assets/images/banner.jpg")} />
          <source media="(max-width:768px)" srcset={require("../assets/images/mbanner.jpg")} />
          <img src={require("../assets/images/banner.jpg")} alt="Flowers" style={{ width: '100%' }} />
        </picture>

        <div className="carousel-caption carousel-content ">
          <h3>
            Unbox Mysuru Biryani
          </h3>

        </div>
      </div>


      <section className="py-5 bg-full-dark-1">
        <div className="container about-box">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Our Story</h2>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-6 p-0">
              <img
                src={require("../assets/images/our-story-rice.png")}
                className="w-100"
              />
            </div>
            <div className="col-md-6 bg-warning1 p-0 d-flex align-items-center justify-content-center">
              <div className=" px-md-4 px-3 ">
                {/* <h2 className="text-dark fs-1 fw-semibold text-start">
                  Lorem ipsum
                </h2> */}
                <p className="lh-md text-left abt-cont">
                  Welcome to our journey at Mysuru Biriyaani, where tradition and innovation converge to create a culinary experience unlike any other. It all started with a simple Sunday experiment that my spouse and I embarked on, driven by our shared passion for wholesome meals and a desire to reinvent the timeless favorite, biriyaani, into a healthier rendition. Drawing inspiration from the rich culinary heritage of Mysuru, we meticulously crafted a recipe that strikes the perfect balance between tradition and modern sensibilities, ensuring that each bite is infused with love and authenticity. As word of our delectable creation spread, so did the demand, turning our humble experiment into a thriving culinary venture. Today, we invite you to join us in savoring the taste of tradition and homemade goodness with every plate of Mysuru Biriyaani, as we continue to push the boundaries of flavor and tradition, one dish at a time.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-5 d-block mx-auto text-center mb-5 w-100">
              <h2 className="theme-color mb-3">Unbox <br />Mysore Biriyaani</h2>
            </div>
            <div className=" col-md-9 d-block mx-auto ">
              <img
                src={require("../assets/images/Unbox Mysuru Biryani.png")}
                className="img-fluid  mx-auto mt-n12 px-md-5 px-1 d-lg-block d-none"
              />

              <img
                src={require("../assets/images/tmob-unbox.png")}
                className="img-fluid  mx-auto mt-n12 px-md-5 px-1 d-block d-lg-none"
              />
            </div>
          </div>
          <div className="row">

          </div>
        </div>
      </section>


      <section className="py-5 bg-body-secondary vh-75">
        <div className="container " style={{ minHeight: "450px" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className='text-dark'>Why Local Heros</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 pt-lg-5 pt-0">
                <img src={require("../assets/images/custom-spice-blends.png")} className="w-75 d-block mx-auto" alt="" />
              </div>
              <div className="col-lg-4 pt-5">
                <img src={require("../assets/images/vetable.jpg")} className="w-75 d-block veg-img mx-auto" alt="" />
              </div>
              <div className="col-lg-4 pt-5">
                <img src={require("../assets/images/healthy.png")} className="w-75  d-block mx-auto" alt="" />
              </div>
              <div className="col-lg-4 pt-5 ">
                <img src={require("../assets/images/light-on-tummy.png")} className="w-75 d-block mx-auto" alt="" />
              </div>
              <div className="col-lg-4 pt-5 ">
                <img src={require("../assets/images/no-delivery.png")} className="w-75 d-block mx-auto" alt="" />
              </div>
              <div className="col-lg-4 pt-5 ">
                <img src={require("../assets/images/cold-press.png")} className="w-75 d-block mx-auto" alt="" />
              </div>

            </div>




          </div>
        </div>
      </section>



      <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-4">
                            <h2>Blogs</h2>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/blog1.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>The Art of Pairing Mysore Biryani with the Perfect Accompaniments</h3>
                                <p className="pb-3 comn-fnt px-2">Mysore Biryani, with its rich flavors and aromatic spices, is a culinary delight that stands tall among India's diverse biryani offerings. While this iconic dish shines on its own... </p>
                            </div>
                            <Link to={`/blog/blogs`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/blog2.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'> From Street Food to Fine Dining: Biryani's Journey Through Culinary History</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, with its aromatic blend of spices and succulent meat or vegetables layered with fragrant rice, holds a special place in the hearts of food enthusiasts worldwide... </p>
                            </div>
                            <Link to={`./blog2`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/bolg3.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>Exploring Regional Variations: A Tour of India's Biryani Hotspots</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, the crown jewel of Indian cuisine, boasts a rich tapestry of regional variations, each offering a unique blend of flavors, spices, and cooking techniques...</p>
                            </div>
                            <Link to={`./blog3`} className="readmore-button" > Read more </Link>
                        </div>

                    </div>
                </div>
            </section>


      <Footer />
    </>
  );
}


