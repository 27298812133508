import React from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";


export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />

      <div className="carousel slide" data-bs-ride="carousel">

<picture>
    <source media="(min-width:768px)" srcset={require("../assets/images/banner.jpg")} />
    <source media="(max-width:768px)" srcset={require("../assets/images/mbanner.jpg")} />
    <img src={require("../assets/images/banner.jpg")} alt="Flowers" style={{ width: '100%' }} />
</picture>

<div className="carousel-caption carousel-content ">
    <h3>
        Unbox Mysuru Biryani
    </h3>

</div>
</div>

      <section className="breadcum-bg " style={{ maxHeight: "60px" }}></section>
      <section className="my-md-5 mb-3  bg-light"></section>
      <section className="py-lg-5 ">
        <div className="container about-box cont-bg">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Contact Us</h2>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-4 pb-5 pb-lg-0">
              <img
                src={require("../assets/images/contact-left.png")}
                className="w-100"
              />
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-center">
              <div className=" px-md-5 px-3 ">
                <form id="footer-form" className="cont-bg" style={{ paddingBottom: "15px" }}>
                  <div className="row mb-2">
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="fname"
                          name="name"
                          placeholder="First Name"
                          type="text"
                        />
                        <label for="fname">First Name</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="fname"
                          name="name"
                          placeholder="Last Name"
                          type="text"
                        />
                        <label for="lname">Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        <label for="email">Email</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <input
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          type="text"
                        />
                        <label for="phone">Phone Number</label>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 md-mb-0 mb-3">
                      <div className="form-floating">
                        <textarea
                          className="form-control border-0 border-bottom rounded-0 pb-3"
                          placeholder="Write your message"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label for="floatingTextarea2">Message</label>
                      </div>
                    </div>
                  </div>

                  <button
                    id="f_btn1"
                    className="footer-button d-flex mx-auto mt-3 rounded"
                    type="submit"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </section>



      <Footer />
    </>
  );
}
