import React, { useState, useEffect } from 'react';

import { Link, useLocation } from "react-router-dom";


export default function Header() {

  const location = useLocation();
  const [navbarBg, setNavbarBg] = useState('transparent');

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const changeColorPosition = 100; // Change color after scrolling down 100 pixels

    if (scrollPosition > changeColorPosition) {
      setNavbarBg('bg-theme-dark text-white');
    } else {
      setNavbarBg('transparent text-dark');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  return (
    <header>
      <div className="container-fluid ">
        {/* Navbar */}
        <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${navbarBg} px-md-5 px-3 mobNav`}>
          <Link className="navbar-brand" to="/">
            <img src={require("../assets/images/logo.webp")} className="logo-wid" alt="Logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav nav-ul">
              <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/menu' ? 'active' : ''}`}>
                <Link className="nav-link" to="/menu">Menu</Link>
              </li>
              
              <li className={`nav-item ${location.pathname === '/blog' ? 'active' : ''}`}>
                <Link className="nav-link" to="/blog">Blog</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                <Link className="nav-link" to="/contact">Contact</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/#' ? 'active' : ''}`}>
                <Link className="nav-link" to="#">Sign In</Link>
              </li>
              <ll className='nav-item' style={{ marginTop: '10px' }}>
                <Link to="#" className="button">Sign Up</Link>
              </ll>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
