import React from 'react';
import { Helmet } from 'react-helmet';
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";

export default function Menu() {
    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta name="description" content="This is a description of your page." />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>
            <Header />

            <div className="carousel slide" data-bs-ride="carousel">

                <picture>
                    <source media="(min-width:768px)" srcset={require("../assets/images/banner.jpg")} />
                    <source media="(max-width:768px)" srcset={require("../assets/images/mbanner.jpg")} />
                    <img src={require("../assets/images/banner.jpg")} alt="Flowers" style={{ width: '100%' }} />
                </picture>

                <div className="carousel-caption carousel-content ">
                    <h3>
                        Unbox Mysuru Biryani
                    </h3>

                </div>
            </div>

            {/* <div className="carousel slide" data-bs-ride="carousel">

                <picture>
                    <source media="(min-width:768px)" srcset={require("../assets/images/Banner3.webp")} />
                    <source media="(max-width:768px)" srcset={require("../assets/images/mobile-banner2.webp")} />
                    <img src={require("../assets/images/Banner3.webp")} alt="Flowers" style={{ width: '100%' }} />
                </picture>


                <div className="carousel-content d-md-block d-none">
                    <h3>

                        The no nonsense <br />Biriyaani
                    </h3>
                    <Link to="/contact" className="button">Contact</Link>
                </div>
            </div> */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-4">
                            <h2 className='theme-color'>Menu</h2>
                        </div>
                    </div>
                    <div className='menu-box-bg'>
                        <div className='row justify-content-center'>
                            <div className="col-md-12 mb-5 text-center">
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item menu-nav" role="presentation">
                                        <button class="nav-link me-0 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Biriyaani by Box</button>
                                    </li>
                                    <li class="nav-item menu-nav" role="presentation">
                                        <button class="nav-link me-0" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Biriyaani by Grams</button>
                                    </li>
                                    <li class="nav-item menu-nav" role="presentation">
                                        <button class="nav-link me-0" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Chicken Koliwada by grams
                                            (Kebab) </button>
                                    </li>
                                    <li class="nav-item menu-nav" role="presentation">
                                        <button class="nav-link me-0" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact1" type="button" role="tab" aria-controls="pills-contact1" aria-selected="false">Blockbuster Biriyaani Combo</button>
                                        {/* Ultimate Bowl  */}
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div className='row'>
                                            <div className='col-md-3 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Epic Mysore Biriyaani
                                                    </h3>
                                                    <p className='mb-2 men-p1'>Yummilicious Chicken Biriyaani (with skin)</p>
                                                    <p className='men-p2'>Savor the rich blend of aromatic spices and tender meats in every mouthful of our Epic Mysore Biriyaani.</p>
                                                </div>
                                                <Link to="#" className="price-button" > ₹129.00 </Link>
                                            </div>

                                            <div className='col-md-3 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Epic Mysore Biriyaani
                                                    </h3>
                                                    <p className='mb-2 men-p1'>Yummilicious Chicken Biriyaani (without skin)</p>
                                                    <p className='men-p2'>Savor the rich blend of aromatic spices and tender meats in every mouthful of our Epic Mysore Biriyaani.</p>
                                                </div>
                                                <Link to="#" className="price-button" >  ₹129.00 </Link>
                                            </div>

                                            <div className='col-md-3 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu1.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Classic Biriyaani
                                                    </h3>
                                                    <p className='mb-2 men-p1'>All time favorite chicken Biriyaani</p>
                                                    <p className='men-p2'>Indulge in the rich flavors of tradition with our Classic Mysore Biriyaani.</p>
                                                </div>
                                                <Link to="#" className="price-button" >  ₹129.00 </Link>
                                            </div>
                                            <div className='col-md-3 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu2.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Gen - Z Biriyaani
                                                    </h3>
                                                    <p className='mb-2 men-p1'>Boneless Chicken Biriyaani</p>
                                                    <p className='men-p2'>A compelling blend of aromatic spices and tender meats, crafted to delight the next generation.</p>
                                                </div>
                                                <Link to="#" className="price-button" >  ₹129.00 </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-md-6 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Biryani By Gram
                                                    </h3>
                                                    <p>3000g of rice and 1000g of chicken</p>
                                                    <p >Serves 8-10 people</p>
                                                    <p > (Made to order)</p>
                                                </div>
                                                <Link to="#" className="price-button" >  ₹990.00 </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <div className='row'>
                                            <div className='col-md-4 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Chicken Koliwada by grams (Kebab - 250 gm)
                                                    </h3>
                                                    <p>Savor expertly crafted kebabs bursting with authentic flavors</p>
                                                </div>
                                                <Link to="#" className="price-button" > ₹150.00 </Link>
                                            </div>
                                            <div className='col-md-4 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Chicken Koliwada by grams (Kebab - 500 gm)
                                                    </h3>
                                                    <p>Savor expertly crafted kebabs bursting with authentic flavors</p>
                                                </div>
                                                <Link to="#" className="price-button" > ₹275.00 </Link>
                                            </div>
                                            <div className='col-md-4 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />
                                                    <h3 className='theme-color'>
                                                        Chicken Koliwada by grams (Kebab - 1000 gm)
                                                    </h3>
                                                    <p>Savor expertly crafted kebabs bursting with authentic flavors</p>
                                                </div>
                                                <Link to="#" className="price-button" > ₹525.00 </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact1-tab">
                                        <div className='row d-flex justify-content-center'>
                                            <div className='col-md-4 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu.png")} className='img-fluid' alt="menu" />

                                                </div>

                                            </div>
                                            <div className='col-md-4 mb-4'>
                                                <div className='menu-box'>
                                                    <img src={require("../assets/images/menu1.png")} className='img-fluid' alt="menu" />

                                                </div>

                                            </div>
                                            <p className='theme-color font-siz'>
                                                Blockbuster Biriyaani Combo
                                            </p>
                                            <p className='text-white'>3000 gm cooked Biriyaani rice <br />
                                                1000 gm  cooked Biriyaani chicken <br />
                                                1000 gm  kabab</p>

                                            <p className='text-white'>(Serves 10 people)
                                            </p>

                                            <Link to="#" className="price-button" > ₹1490.00 </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menu-content'>
                            <h3 className='theme-color'><span>90 Minutes - made to order from scratch</span></h3>
                            <h3 className='text-white'>“Don't eat stale, when you can eat fresh”</h3>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
