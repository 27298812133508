import React from 'react'
import { Helmet } from 'react-helmet';
import Header from "../componets/Header";
import Footer from "../componets/Footer";

import { Link } from "react-router-dom";
import { Button, Container } from 'react-bootstrap';

export default function Home() {

    return (
        <>
            <Helmet>
                <title>Local Heroes</title>
                <meta name="description" content="This is a description of your page." />
                <meta name="keywords" content="local heroes, community, volunteer" />
            </Helmet>

            <Header />

            <section>

                <div className='pos-rel'>
                    <picture>
                        <source media="(min-width:768px)" srcset={require("../assets/images/banner.jpg")} />
                        <source media="(max-width:768px)" srcset={require("../assets/images/mbanner.jpg")} />
                        <img src={require("../assets/images/banner.jpg")} alt="Flowers" style={{ width: '100%' }} />
                    </picture>
                    <div className="carousel-caption carousel-content ">
                        <h3>
                            Unbox Mysuru Biryani
                        </h3>

                    </div>
                </div>

            </section>

            <section className='py-5 why-bryani'>
                <div className='container'>
                    <h2 className="mb-3 mt-2 ">Why Is Biryani a Celebration?</h2>
                    <p>
                        Biryani transcends mere sustenance; it embodies an emotion that evokes joy and festivity. With its tantalizing aroma and rich flavors, biryani has a unique ability to uplift spirits and unite people in celebration. It is not just a dish; it is a cultural icon, symbolizing the richness of our culinary heritage. From its aromatic spices to its succulent meat or vegetables, every bite of biryani is a journey through tradition and flavor. It is this immersive experience that makes biryani the undisputed star of festivities across our nation, bringing communities together and creating cherished memories that last a lifetime.

                    </p>

                    <h2 className="mb-3 mt-5 ">Unraveling the Mysteries Behind Biryani's Origins</h2>
                    <p>
                        As the tantalizing aroma of biryani wafts through the air, so do the tales of its origin dance through history.  <br /> <br />

                        Delving into the annals of Tamil literature, we uncover whispers of a dish known as Oon Soru, dating back to the year 2 A.D. This ancient concoction comprised rice, ghee, meat, and an exquisite blend of spices including turmeric, coriander, pepper, and bay leaf. Legends suggest it was crafted to nourish valiant warriors on the battlefield, infusing them with strength and courage.<br /><br />

                        Over time, biryani evolved, branching into myriad regional interpretations, each boasting its own distinctive flair. From the regal Hyderabadi Biryani to the fragrant Lucknowi Biryani, and from the coastal allure of Thalassery Biryani to the robust flavors of Ambur Biryani, the tapestry of biryani varieties continues to expand, weaving a culinary saga as diverse as the lands from which they hail. And amidst this rich tapestry, countless other variations emerge, each with its own story to tell, captivating palates and igniting a passion for exploration.

                    </p>
                </div>
            </section>


            <section className="about py-5 bg-2 d-none">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <h4 className='d-block d-lg-none h4mob'>Flip Side of Biryani</h4>
                        <div className='col-md-7'>
                            <img src={require("../assets/images/about.webp")} className='img-fluid w-75 d-block mx-auto' alt="about" />
                        </div>
                        <div className='col-md-5 mt-4 mt-md-0'>
                            <div className='about-box bg-theme-color'>
                                <h4 className='d-lg-block d-none'>Flip Side of Biryani</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conseq</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bgg-3'>
                <div className="bg-3">
                    <div className="container">

                        <div className="row py-4">
                            {/* <h3 className="text-white text-center my-5"></h3> */}
                            <h2 className="mb-5 mt-2 " style={{ color: '#e57228' }}>Few Famous Biryani styles</h2>
                            <div className="col-md-3 mb-4">
                                <img src={require("../assets/images/biryani.png")} className='img-fluid d-block mx-auto' alt="about" />
                            </div>
                            <div className="col-md-9 text-center mb-4">
                                <h3 className="theme-font">Hyderabadi Biryani</h3>
                                <p className="text-white ">Known for its aromatic  basmati rice, tender meat, and signature blend of spices,<br className='d-lg-block d-none' /> Hyderabad's biryani delights with its rich flavors and royal heritage.</p>
                            </div>
                            <div className="col-md-3 mb-4 d-blcok d-md-none">
                                <img src={require("../assets/images/biryani2.png")} className='img-fluid d-block mx-auto' alt="about" />
                            </div>
                            <div className="col-md-9 text-center mb-4">
                                <h3 className="theme-font">Lucknowi Biryani</h3>
                                <p className="text-white ">Fragrant rice, succulent meat, and a blend of exotic spices epitomize Lucknow's biryani,<br className='d-lg-block d-none' /> offering a regal culinary experience steeped in nawabi tradition.</p>
                            </div>
                            <div className="col-md-3 mb-4 d-none d-md-block">
                                <img src={require("../assets/images/biryani2.png")} className='img-fluid d-block mx-auto' alt="about" />
                            </div>
                            <div className="col-md-3 mb-4">
                                <img src={require("../assets/images/biryani3.png")} className='img-fluid d-block mx-auto' alt="about" />
                            </div>
                            <div className="col-md-9 text-center mb-4">
                                <h3 className="theme-font">Kolkata Biryani</h3>
                                <p className="text-white ">Infused with rose water and saffron, Kolkata's biryani offers a unique blend of flavors <br className='d-lg-block d-none' /> reflecting the city's diverse culinary heritage.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className='bg22 py-5'>

                <div className=" ">
                    <h2>Introducing</h2>
                    <h2><span className="theme-color" style={{ color: '#cd7b05' }}>The Mysore Style Biriyaani By Local Heroes</span></h2>
                </div>
                <img src={require("../assets/images/logo.webp")} className='mid-logo d-block mx-auto pb-4 pt-5' />
                <p className="bg-poss text-center">
                    Step into the realm of gastronomic delight with Local Heroes, where tradition meets innovation in every delectable bite. As a tech-enabled specialty food company, we pride ourselves on crafting premium yet affordable culinary treasures, none more tantalizing than our Mysore style biriyaani. <br /> <br />

                    Prepare to embark on a journey of flavor that will tantalize your taste buds and invigorate your senses. Our yummilicious biriyaani is not just a meal; it's a symphony of carefully curated ingredients, harmoniously blended with our secret masala comprising no more than 15 distinct spices. Each grain of rice is infused with the essence of our practiced technique and cooked to perfection using the ancient dum pukht method.
                    <br /> <br />

                    Indulge in the irresistible allure of our biriyaani, where every mouthful is a celebration of Mysore's rich culinary heritage. Let our biriyaani nourish your body and refresh your mind, leaving you craving for more with each unforgettable bite. Experience the magic of Local Heroes' Mysore style biriyaani – a culinary masterpiece crafted by local heroes for heroes like you.


                </p>
            </section>

            <section className="about-bg py-5 d-none">
                <div className="container">
                    <div className="about-inner-bg py-5 position-relative">
                        <div className="row justify-content-center">
                            <div className="col-md-10 text-center">
                                <h2 className="mb-4">About Us</h2>
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit</p>
                                <p className="mb-4">in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                        </div>
                        <div className="about-but-pos">
                            <h3>Unbox <br />Mysuru Biryani</h3>

                            <img src={require("../assets/images/biryani3.png")} alt='' className='w-75 d-lg-none d-block mx-auto pb-5 pt-3' />

                            <button className="footer-button d-block mx-auto" type="submit"> Sign Up </button>
                        </div>
                    </div>

                </div>
            </section>

            <section className="bg-4 py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 text-white text-center">
                            <h2>USP'S</h2>
                            <p className="mt-3">If you don't experience bloating, heartburn, thirst, or exhaustion after eating biriyani, then it's a good biriyani.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-md-2 col-6 mb-4 mb-md-0">
                            <div className="bg-theme-color ups-icon">
                                <img src={require("../assets/images/ups-icon1.png")} className="" alt="icon" />
                                <p className='mb-0'>Hygiene Standards</p>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 mb-md-0">
                            <div className="bg-theme-color ups-icon">
                                <img src={require("../assets/images/ups-icon2.png")} className="" alt="icon" />
                                <p className='mb-0'>Quality Ingredients</p>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 mb-md-0">
                            <div className="bg-theme-color ups-icon">
                                <img src={require("../assets/images/ups-icon3.png")} className="" alt="icon" />
                                <p className='mb-0'>Flavorful Varieties</p>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 mb-md-0">
                            <div className="bg-theme-color ups-icon">
                                <img src={require("../assets/images/ups-icon4.png")} className="" alt="icon" />
                                <p className='mb-0'>Customer Satisfaction</p>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="bg-theme-color ups-icon">
                                <img src={require("../assets/images/ups-icon5.png")} className="" alt="icon" />
                                <p className='mb-0'>Commitment to Service</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-4">
                            <h2>Blogs</h2>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/blog1.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>The Art of Pairing Mysore Biryani with the Perfect Accompaniments</h3>
                                <p className="pb-3 comn-fnt px-2">Mysore Biryani, with its rich flavors and aromatic spices, is a culinary delight that stands tall among India's diverse biryani offerings. While this iconic dish shines on its own... </p>
                            </div>
                            <Link to={`/blog/blogs`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/blog2.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'> From Street Food to Fine Dining: Biryani's Journey Through Culinary History</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, with its aromatic blend of spices and succulent meat or vegetables layered with fragrant rice, holds a special place in the hearts of food enthusiasts worldwide... </p>
                            </div>
                            <Link to={`./blog2`} className="readmore-button" > Read more </Link>
                        </div>
                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="blog-box mb-4">
                                <img src={require("../assets/images/bolg3.jpg")} className="img-fluid mb-3" />
                                <h3 className='px-2'>Exploring Regional Variations: A Tour of India's Biryani Hotspots</h3>
                                <p className="pb-3 comn-fnt px-2">Biryani, the crown jewel of Indian cuisine, boasts a rich tapestry of regional variations, each offering a unique blend of flavors, spices, and cooking techniques...</p>
                            </div>
                            <Link to={`./blog3`} className="readmore-button" > Read more </Link>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
