import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import BlogDetail from './pages/Blogs';
import Blog2 from './pages/blog2';
import Blog3 from './pages/blog3';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />

          <Route path="/blog/:slug/blog2" element={<Blog2 />} />
          <Route path="/blog/:slug/blog3" element={<Blog3 />} />

          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
