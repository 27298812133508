import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="pt-5">
      <div className="container text-white">
        <div className="row text-center align-items-center border-top pt-4 border-white">
          <div className="col-md-4 md-mb-0 mb-4">
            <img src={require("../assets/images/logo.webp")} className="w-75" alt="Logo" />
          </div>
          <div className="col-md-4 text-center md-mb-0 mb-4">
            <p>
              3rd Floor, 9th, A Main 559, <br />9th A Main Rd, 1st Stage, Indiranagar, <br />Bengaluru, Karnataka 560008
            </p>
            <p>
              Phone: 1234567890
            </p>
            <p>
              Email: abcd@gmail.com
            </p>
            <ul className="social ">
              <li>
                <Link href="#" target="_blank">
                  <i className="fab fa-facebook"></i>
                </Link>
              </li>
              <li>
                <Link href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>
                </Link>
              </li>
              <li>
                <Link href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className='text-white'>Contact Us</h5>
            <form
              id="footer-form"
              style={{ paddingBottom: '15px' }}
            >
              <div className="row mb-2">
                <div className="col-md-6 col-sm-12 md-mb-0 mb-2">
                  <input
                    className="form-control"
                    id="fname"
                    name="name"
                    placeholder="Name"
                    type="text"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <input
                    className="form-control"
                    id="lname"
                    name="email"
                    placeholder="Email"
                    type="text"
                  />
                </div>
              </div>
              <div className="row mb-lg-2">
                <div className="col-3">
                  <select
                    name="ccode"
                    placeholder="contrycode"
                    className="form-control md-mb-0 mb-2"
                  >
                    <option value="+91">+91</option>
                  </select>
                </div>

                <div className="col-9">
                  <input
                    className="form-control"
                    name="phone"
                    placeholder="Phone"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    rows="3"
                    name="message"
                  ></textarea>
                </div>
              </div>
              <button
                id="f_btn1"
                className="footer-button d-flex mx-auto mt-3"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>

      </div>
      <div className='border-top border-white bg-orange  mt-3 py-3 '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 ps-0'>
              <ul className="quick-links d-flex mb-0 ps-0 me-0 text-dark">
                <li><Link to="/" className='text-decoration-none text-dark  pr-3'>Home</Link></li>
                <li><Link to="/about" className='text-decoration-none text-dark '>About Us</Link></li>
                <li><Link to="/menu" className='text-decoration-none text-dark '>Menu</Link></li>
                <li><Link to="/blog" className='text-decoration-none text-dark '>Blog</Link></li>
                <li><Link to="/contact" className='text-decoration-none text-dark '>Contact</Link></li>
              </ul>
            </div>
            <div className='col-md-4 text-center'>
              <p className='mb-0 text-center copy-rights'>Copyright © 2024 Local Heros Pvt Ltd</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
